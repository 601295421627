import React from 'react'
import PageSeo from '../../components/SEO'
import WhyIbit from '../../components/WhyIbit/WhyIbit'

function index() {
    return (
        <div>
            <PageSeo pageTitle="Why InfluencerBit"/>
            <WhyIbit/>
        </div>
    )
}

export default index
