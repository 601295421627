import React from "react"
import PricingNavbar from "../Pricing/PricingNavbar/PricingNavbar"
import "../../styles/WhyIbit/WhyIbit.css"
import whyIbitImage from "../../../static/assets/whyIbitImg.png"
import InfluencerFooter from "../LandingSubComponents/InfluencerFooter/InfluencerFooter"

function WhyIbit() {
  return (
    <div className="whyIbitCol">
      <PricingNavbar />
      <div className="container is-fluid whyIbitContainer">
        <div className="columns is-multiline is-mobile">
          <div className="column is-12-desktop has-text-centered">
            <h1 className="has-text-black whyIbitHeader is-size-2-desktop is-size-3-tablet is-size-3-mobile">
              Why Influencerbit?
            </h1>
            <div className="whyIbitImgBox">
              <img
                src={whyIbitImage}
                alt="whyIbitImg"
                title="whyIbitImg"
                className="image whyIbitImg"
                loading="lazy"
              />
            </div>
            <p className="whyIbitBcsText is-size-5 mt-6 is-uppercase has-text-black">
              Influencers are supposed to be "real people" who stood for something they really believe in.
            </p>
            <p className="whyIbitFasterText mt-3 has-text-black is-size-2-desktop is-size-3-tablet is-size-3-mobile">
              Paying random Influencers<br className="is-hidden-tablet"/> to post is<br className="is-hidden-tablet"/> NOT influencer marketing
            </p>
            
          </div>
         
          
        </div>
        <div className="columns is-multiline is-mobile">
          <div className="column is-6-desktop is-12-mobile is-12-tablet has-text-centered is-offset-3-desktop">
          <p className=" has-text-black mt-3  is-size-6">

            We are sad at the current state of Influencer marketing. Agencies coming up and selling influencers' packages. These people who are promoting have no idea about the brand / product
            and are doing it just for the money and promoting anything!

            <br />
            <br />
            Any post I see by a big celebrity endorsing a brand is an advertisement. They are getting money to promote this. Remember #FYRE festival?

            <p className="whyIbitFasterText mt-3 has-text-black is-size-2-desktop is-size-3-tablet is-size-3-mobile">
            Yes, the Nano Influencers!
            </p>
            <br />
            If my friend with ~500 followers is posting about this crank top, I would be more than happy to ask her about it and check it out!
            <p className="whyIbitFasterText mt-3 has-text-black is-size-2-desktop is-size-3-tablet is-size-3-mobile">
              But you need an army of them!
            </p>

            <br />
            <strong>That is the problem which we are solving at influencerbit</strong>

            <br />
            <br />
            <p className="whyIbitFasterText mt-3 has-text-black is-size-2-desktop is-size-3-tablet is-size-3-mobile">
             Automate "Customer Centric" Influencer Marketing at Scale
            </p>
            <br/>
            <br />
            Our tool does all! Right from Capturing people who love your brand, Enticing them to post, tracking statistics and sending rewards! <br /><br />
            We are in influencer space since past 3 years. Having built multiple automation tools in the past for influencers and having talked with multiple brands, we realised
            a big gap and massive need for automation in this space.
            <br />
            <br />
            We are all in for this and are bootstrapping. We are a lean team and believe in power of feedback. Let us know if you need any feature or integration or if anything is not working.
            We are active on support all the time and would love to hear everything ~ The good, bad, ugly, everything!
            </p>
            <br />

          </div>
          </div>
      </div>
      <div className="mt-3">
          <InfluencerFooter/>
      </div>
    </div>
  )
}

export default WhyIbit
